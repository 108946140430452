import './App.css';
import { Box, Grid, TextField, Button, Typography } from '@mui/material';
import { useState }  from 'react';
import React from 'react';
import { makeStyles } from '@mui/styles';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
    backgroundImage: `url(${process.env.PUBLIC_URL}/background.png)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  input: {
    width: '100%',
    marginBottom: 2,
    backgroundColor: 'rgba(255, 255, 255, 0.5)', // Adjust the opacity as needed
    borderRadius: 12, // Adjust the border radius as needed
  },
  button: {
    width: '100%',
    marginBottom: 2,
    borderRadius: 12, // Adjust the border radius as needed
  },
  output: {
    width: '100%',
  },
  logo: {
    position: 'absolute',
    top: 20,
    left: 'center',
    width: 200
  },
  textbox : {
    padding: 10,
    border: '1px solid #888888',
    borderRadius: 8,
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
  }
}));

function App() {
  const classes = useStyles();
  const [vin, setVin] = useState("");
  const [miles, setMiles] = useState("");
  const [cco, setCco] = useState("");

  const handleVin = () => {
    fetch(`/api/v1/vehicle/cco?vin=${vin}&miles=${miles}`)
    .then((response) => response.json())
    .then((json) => setCco(json["cco"]));
  }

  return (
    <div className={classes.root}>
      <img src={`${process.env.PUBLIC_URL}/logo.png`} alt="Logo" className={classes.logo} />
      <Grid container spacing={2} direction="column" justifyContent="center" alignItems="center" className={classes.root}>

        <Grid item xs={12} sm={6}>
          <Box className={classes.textbox}>
            <Typography variant="body1">
              Find your exact carbon footprint
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
              className={classes.input}
              label="VIN number"
              placeholder="VIN number"
              fullWidth
              value={vin}
              onChange={(e) => setVin(e.target.value)}
              onKeyPress={(ev) => {
                if (ev.key === 'Enter') {
                  handleVin();
                  ev.preventDefault();
                }
              }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
              className={classes.input}
              label="Miles driven"
              placeholder="Miles driven"
              fullWidth
              value={miles}
              onChange={(e) => setMiles(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button 
            className={classes.button}
            variant="contained"
            onClick={handleVin}
            sx={{width: 150, bottom: 3}}>
              Calculate
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          { cco &&
          <Box className={classes.textbox}>
            <Typography variant="body1">
              {cco}
            </Typography>
          </Box>
          }
        </Grid>
      </Grid>
    </div>
  );
}

export default App;